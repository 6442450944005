import request from "@/utils/request";
// import QS from "qs";


// 退出方法
export function logout() {
  return request({
    url: "/logout",
    method: "post",
  });
}

// 登录
export function login(username, password) {
  const data = {
    username,
    password,
  };
  return request({
    url: "/login",
    method: "post",
    data: data,
  });
}
// 注册
export function register(data) {
  return request({
    url: "/register",
    method: "post",
    data: data,
  });
}
// 获取图形验证码
export function send_graphic() {
  return request({
    url: "/send_graphic",
    method: "get",
  });
}
// 获取手机验证码
export function phone_verification(data) {
  return request({
    url: "/phone_verification",
    method: "POST",
    data: data,
  });
}
// 获取手机验证码
export function send_verification_code(data) {
  return request({
    url: "/send_verification_code",
    method: "POST",
    data: data,
  });
}
// 修改密码（密码）
export function retrieve_password(data) {
  return request({
    url: "/retrieve_password",
    method: "POST",
    data: data,
  });
}
