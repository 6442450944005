<template>
    <section>
        <!-- name="fade-transform" -->
        <transition  mode="out-in"> 
			<keep-alive :max="20" exclude="ForgetPassword,ManagementCenter,SingleItem,PersonalInfo,QA,login,index,CompetitionList">
					<router-view :key="key" />
			</keep-alive>
        </transition>
    </section>
</template>

<script>
export default {
    name: "AppMain",
    computed: {
        notCacheName() {
            var visitedViews = this.$route; //this.$store.state.tagsView.visitedViews
            var noCacheViews = [];
            Object.keys(visitedViews).some((index) => {
                if (visitedViews[index].meta) {
                    noCacheViews.push(visitedViews[index].name);
                }
            });
            return noCacheViews;
        },
        key() {
            return this.$route.path;
        },
    },
};
</script>

<style>
</style>