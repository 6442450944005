<template>
  <div>
    <div id="H5app" v-if="this.isMobile">
      <router-view />
    </div>
    <div id="app" v-if="!this.isMobile">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      isMobile: false,
    };
  },
  watch: {
  },
  created(){
    
    
  },
  mounted() {
    this.isMobile = this.isMobileFun()
    // 初始化判断
    const CurrentRoute = window.location.hash.replace('#','')
    if (this.isMobile) {
      if(CurrentRoute == '/index'){
        this.$router.replace({ path: "/" });
      }else if(CurrentRoute == '/CompetitionList'){
        this.$router.replace({ path: "/CompetiH5" });
      }else if(CurrentRoute == '/login'){
        this.$router.replace({ path: "/H5login" });
      }else if(CurrentRoute.indexOf('/SingleItem') == 0){
        location.replace(window.location.href.replace('/SingleItem','/SingleItemH5'))
      }else if(CurrentRoute == '/ManagementCenter'){
        this.$router.replace({ path: "/ManagementCenterH5" });
      }else if(CurrentRoute == '/PersonalInfo'){
        this.$router.replace({ path: "/PersonalInfoH5" });
      }else{
      }
    } else {
      this.isMobile = false;
      if(CurrentRoute == '/'){
        this.$router.replace({ path: "/index" });
      }else if(CurrentRoute == '/CompetiH5'){
        this.$router.replace({ path: "/CompetitionList" });
      }else if(CurrentRoute == '/H5login'){
        this.$router.replace({ path: "/login" });
      }else if(CurrentRoute.indexOf('/SingleItemH5')==0){
        location.replace(window.location.href.replace('/SingleItemH5','/SingleItem'))
      }else if(CurrentRoute == '/ManagementCenterH5'){
        this.$router.replace({ path: "/ManagementCenter" });
      }else if(CurrentRoute == '/PersonalInfoH5'){
        this.$router.replace({ path: "/PersonalInfo" });
      }else{
      }
    }
  },
  methods: {
    isMobileFun() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag;
    },
  },
  created() {},
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  background-color: #f5fafc;
  box-sizing: border-box;
}
#app {
  position: relative;
  /* width: 1920px; */
  /* width: 100%;
  min-width: 1080px; */
  max-width: 1920px;
  min-width: 1080px;
  margin: 0 auto;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(245, 250, 252);
  box-sizing: border-box;
  overflow: hidden;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-track {
  border-radius: 7px;
  background-color: rgba(0,0,0,0.1);
}
::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: rgba(0,0,0,0.5);
}
#H5app {
  width: 100vw;
  margin: 0 auto;
  font-size: 12px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(245, 250, 252);
  box-sizing: border-box;
}
</style>
