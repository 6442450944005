import { login, logout } from "@/api/login";
import { getToken, setToken, removeToken } from "@/utils/auth";

const user = {
  state: {
    token: getToken(),
    name: "",
    roles: [],
    permissions: [],
    IsInside: true,
    NoReadingNumber: 0,
    iSLoginPage: 1,
    ManagementStatus: false,
    EntryPermissions: [],
    // getInfoAll:''//个人信息
  },

  mutations: {
    initWebSockets(state, data) {
      state.NoReadingNumber = data;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    CHANGE_Management: (state, data) => {
      state.ManagementStatus = data;
    },
    SET_EntryPermissions: (state, data) => {
      state.EntryPermissions = data;
      sessionStorage.setItem("EntryPermissions", JSON.stringify(data));
    },
    // SET_INFO: (state, name) => {
    //     state.getInfoAll = name;
    // },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    IsInsideState: (state, permissions) => {
      state.IsInside = permissions;
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim();
      const password = userInfo.password;
      return new Promise((resolve, reject) => {
        login(username, password)
          .then((res) => {
            setToken(res.token);
            commit("SET_TOKEN", res.token);
            if (res.permission_info[0] != 0) {
              if (
                res.permission_info[0] == 99 &&
                typeof res.permission_info[0] == "number"
              ) {
                commit("CHANGE_Management", true);
                commit("SET_EntryPermissions", [0, 1, 2, 3, 4, 10, 11 ,12]); //超级管理员附有所有入口权限
                sessionStorage.setItem("level_info", res.permission_info[0]);
              } else {
                commit("CHANGE_Management", true);
                sessionStorage.setItem(
                  "level_info",
                  JSON.stringify(res.permission_info)
                );
                let EntryPermissions = []
                if (res.permission_info.includes("查看用户")) {
                  EntryPermissions.push(0)
                }
                if (res.permission_info.includes("添加用户")) {
                  EntryPermissions.push(1);
                }
                if (res.permission_info.includes("添加比赛")) {
                  EntryPermissions.push(2);
                  EntryPermissions.push(3);
                }
                if (res.permission_info.includes("查看代码")) {
                  EntryPermissions.push(4);
                }
                if (res.permission_info.includes("报名审核")) {
                  EntryPermissions.push(11);
                }
                if (res.permission_info.includes("上传比赛答案")) {
                  EntryPermissions.push(12);
                }
                if (EntryPermissions.length == 0){
                  EntryPermissions.push(2);
                }
                commit("SET_EntryPermissions", EntryPermissions);
                // if (
                //   res.permission_info.includes("查看用户") &&
                //   res.permission_info.includes("添加用户") &&
                //   res.permission_info.includes("添加比赛") &&
                //   res.permission_info.includes("报名审核")
                // ) {
                //   commit("SET_EntryPermissions", [0, 1, 2, 3, 11]);
                // } else if (
                //   res.permission_info.includes("查看用户") &&
                //   res.permission_info.includes("添加用户") &&
                //   res.permission_info.includes("添加比赛")
                // ) {
                //   commit("SET_EntryPermissions", [0, 1, 2, 3]);
                // } else if (
                //   res.permission_info.includes("查看用户") &&
                //   res.permission_info.includes("添加用户") &&
                //   res.permission_info.includes("报名审核")
                // ) {
                //   commit("SET_EntryPermissions", [0, 1, 11]);
                // } else if (
                //   res.permission_info.includes("添加用户") &&
                //   res.permission_info.includes("报名审核")
                // ) {
                //   commit("SET_EntryPermissions", [11, 1]);
                // } else if (
                //   res.permission_info.includes("查看用户") &&
                //   res.permission_info.includes("报名审核")
                // ) {
                //   commit("SET_EntryPermissions", [11, 0]);
                // } else if (
                //   res.permission_info.includes("添加比赛") &&
                //   res.permission_info.includes("报名审核")
                // ) {
                //   commit("SET_EntryPermissions", [11, 2, 3]);
                // } else if (
                //   res.permission_info.includes("查看用户") &&
                //   res.permission_info.includes("添加用户")
                // ) {
                //   commit("SET_EntryPermissions", [0, 1]);
                // } else if (res.permission_info.includes("查看用户")) {
                //   commit("SET_EntryPermissions", [0]);
                // } else if (res.permission_info.includes("添加用户")) {
                //   commit("SET_EntryPermissions", [1]);
                // } else if (res.permission_info.includes("添加比赛")) {
                //   commit("SET_EntryPermissions", [2, 3]);
                // } else if (res.permission_info.includes("报名审核")) {
                //   commit("SET_EntryPermissions", [11]);
                // } else {
                //   commit("SET_EntryPermissions", [2]);
                // }
              }
            } else {
              commit("CHANGE_Management", false);
              commit("SET_EntryPermissions", 0);
              sessionStorage.setItem("level_info", null);
            }

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit("SET_TOKEN", "");
            commit("SET_NAME", "");
            commit("CHANGE_Management", false);
            sessionStorage.clear();
            removeToken();
            resolve();
            window.reload();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit("SET_TOKEN", "");
        commit("SET_NAME", "");
        commit("CHANGE_Management", false);
        sessionStorage.clear();
        removeToken();
        resolve();
        // window.reload();
      });
    },
  },
};

export default user;
