import request from "@/utils/request";

export function change_password(data) {
  return request({
    url: "/change_password",
    method: "post",
    data: data,
  });
}
export function update_user(data) {
  return request({
    url: "/update_user",
    method: "post",
    data: data,
  });
}
// 获取个人信息
export function getInfo() {
  return request({
    url: "/user_details",
    method: "get",
  });
}
// 获取个人比赛信息
export function entry_list() {
  return request({
    url: "/entry_list",
    method: "post",
  });
}
// 搜索比赛
export function MatchList(data) {
	return request({
	url: "/search/MatchList",
	method: "post",
	data: data,
	});
}
// 搜索比赛
export function upload_code(data, id, code_type) {
	return request({
	url: "/upload_code/" + id + "/" + code_type,
	method: "post",
	data: data,
	});
}