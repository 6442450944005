<template>
  <div
    class="home"
    v-loading="loading"
    element-loading-text="数据加载中"
    element-loading-background="rgba(0, 0, 0, 0.2)"
  >
    <div class="Login-header">
      <div class="wrap" :style="ThisRouter == '/index' ? 'max-width: 1080px;':'max-width: 80%;'">
        <div class="logo">
          <span>Credit Lab</span>
        </div>
        <div class="nav">
          <div
            class="item"
            @click="Jump('/index')"
            :style="
              productServiceTitleState == 0
                ? 'color:#2B67EE;font-weight: bold;'
                : ''
            "
          >
            首页
            <div
              class="title-content-underLine"
              :style="
                productServiceTitleState == 0 ? 'transform: scaleX(1);' : ''
              "
            ></div>
          </div>
          <div
            class="item"
            @click="Jump('/CompetitionList')"
            :style="
              productServiceTitleState == 1
                ? 'color:#2B67EE;font-weight: bold;'
                : ''
            "
          >
            比赛列表
            <div
              class="title-content-underLine"
              :style="
                productServiceTitleState == 1 ? 'transform: scaleX(1);' : ''
              "
            ></div>
          </div>
          <div
            v-if="false"
            class="item"
            @click="Jump('/QA')"
            :style="
              productServiceTitleState == 10
                ? 'color:#2B67EE;font-weight: bold;'
                : ''
            "
          >
            问答
            <!-- <span class="sup">1</span> -->
            <div
              class="title-content-underLine"
              :style="
                productServiceTitleState == 10 ? 'transform: scaleX(1);' : ''
              "
            ></div>
          </div>
        </div>
        <div class="login">
          <template v-if="ThisRouter != '/login'">
            <div
              class="home-button"
              @click="LoginPageJump('/login')"
              v-if="iSLoginPage == 0"
            >
              登录
            </div>
          </template>
          <div
            v-if="iSLoginPage == 1 && ManagementCenterState"
            class="item"
            @click="Jump('/ManagementCenter')"
            :style="
              productServiceTitleState == 2
                ? 'color:#2B67EE;font-weight: bold;'
                : ''
            "
          >
            管理中心
            <div
              class="title-content-underLine"
              :style="
                productServiceTitleState == 2 ? 'transform: scaleX(1);' : ''
              "
            ></div>
          </div>
          <div
            class="item"
            @click="Jump('/PersonalInfo')"
            v-if="iSLoginPage == 1"
            :style="
              productServiceTitleState == 3
                ? 'color:#2B67EE;font-weight: bold;'
                : ''
            "
          >
            个人中心
            <div
              class="title-content-underLine"
              :style="
                productServiceTitleState == 3 ? 'transform: scaleX(1);' : ''
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="banner" v-if="ThisRouter == '/index'"></div>
    <div class="appMainStyle">
      <app-main @getTreeData="LodingData" />
    </div>
    <div class="footer">
      <div class="footer-bar">
        <div class="item" @click="RegistrationAgreement">注册协议</div>
        <div class="line"></div>
        <div class="item" @click="UseHelp">使用帮助</div>
        <div class="line"></div>
        <div class="item" @click="trainingmanual">培训手册</div>
        <div class="line"></div>
      </div>
      <!-- <a href="https://beian.miit.gov.cn/#/Integrated/index">蜀ICP备2021022597号</a> -->
      <!-- 蜀ICP备2021022121号 -->
      <div
        style="
          width: 600px;
          margin: 0 auto;
          padding: 10px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: calc(50% - 300px);
          bottom: -5px;
        "
      >
        ICP证:
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          style="line-height: 20px; height: 20px"
        >
          蜀ICP备2021022597号-1
        </a>
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002004358"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
            display: flex;
            line-height: 20px;
            padding-left: 20px;
          "
        >
          <img src="../assets/imgs/9971da80bcc741a5c615f8c1837036d.png" />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            川公网安备 51019002004358号
          </p></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { AppMain } from "../components";
import { getToken } from "@/utils/auth";
import { getInfo } from "../api/ThisInfo.js";
export default {
  name: "Layout",
  components: {
    AppMain,
  },
  data() {
    return {
      loading: null, //加载动画
      ManagementCenterState: false, //管理中心显示状态
      productServiceTitleState: 0, //选中状态项
      iSLoginPage: 1, //是否需要登录  0未登录  1  已登录
      ThisRouter: "",
    };
  },
  created() {
    this.ThisRouter = this.$route.path;
    // 默认判断初始页面是否为注册登录页面
    this.OnClickIsLoginPage();
    // 默认判断选择样式
    this.ChangeState(this.$route.path);
    // 获取权限
    if (getToken()) {
      getInfo().then((res) => {
        // 从新获取权限判断入口权限存放到VueX
        if (res.data.permission_info[0] != 0) {
          if (
            res.data.permission_info[0] == 99 &&
            typeof res.data.permission_info[0] == "number"
          ) {
            this.$store.commit("CHANGE_Management", true);
            this.$store.commit("SET_EntryPermissions", [0, 1, 2, 3, 4, 10, 11 ,12]); //超级管理员附有所有入口权限
            sessionStorage.setItem("level_info", res.data.permission_info);
          } else {
            this.$store.commit("CHANGE_Management", true);
            sessionStorage.setItem(
              "level_info",
              JSON.stringify(res.data.permission_info)
            );
            let EntryPermissions = [];
            if (res.data.permission_info.includes("查看用户")) {
              EntryPermissions.push(0);
            }
            if (res.data.permission_info.includes("添加用户")) {
              EntryPermissions.push(1);
            }
            if (res.data.permission_info.includes("添加比赛")) {
              EntryPermissions.push(2);
              EntryPermissions.push(3);
            }
            if (res.data.permission_info.includes("查看代码")) {
              EntryPermissions.push(4);
            }
            if (res.data.permission_info.includes("报名审核")) {
              EntryPermissions.push(11);
            }
            if (res.data.permission_info.includes("上传比赛答案")) {
              EntryPermissions.push(12);
            }
            if (EntryPermissions.length == 0) {
              EntryPermissions.push(2);
            }
            this.$store.commit("SET_EntryPermissions", EntryPermissions);
            // if (
            //   res.data.permission_info.includes("查看用户") &&
            //   res.data.permission_info.includes("添加用户") &&
            //   res.data.permission_info.includes("添加比赛") &&
            //   res.data.permission_info.includes("报名审核")
            // ) {
            //   this.$store.commit("SET_EntryPermissions", [0, 1, 2, 3, 11]);
            // } else if (
            //   res.data.permission_info.includes("查看用户") &&
            //   res.data.permission_info.includes("添加用户") &&
            //   res.data.permission_info.includes("添加比赛")
            // ) {
            //   this.$store.commit("SET_EntryPermissions", [0, 1, 2, 3]);
            // } else if (
            //   res.data.permission_info.includes("查看用户") &&
            //   res.data.permission_info.includes("添加用户") &&
            //   res.data.permission_info.includes("报名审核")
            // ) {
            //   this.$store.commit("SET_EntryPermissions", [0, 1, 11]);
            // } else if (
            //   res.data.permission_info.includes("添加用户") &&
            //   res.data.permission_info.includes("报名审核")
            // ) {
            //   this.$store.commit("SET_EntryPermissions", [1, 11]);
            // } else if (
            //   res.data.permission_info.includes("查看用户") &&
            //   res.data.permission_info.includes("报名审核")
            // ) {
            //   this.$store.commit("SET_EntryPermissions", [0, 11]);
            // } else if (
            //   res.data.permission_info.includes("添加比赛") &&
            //   res.data.permission_info.includes("报名审核")
            // ) {
            //   this.$store.commit("SET_EntryPermissions", [2, 3, 11]);
            // } else if (
            //   res.data.permission_info.includes("查看用户") &&
            //   res.data.permission_info.includes("添加用户")
            // ) {
            //   this.$store.commit("SET_EntryPermissions", [0, 1]);
            // } else if (res.data.permission_info.includes("查看用户")) {
            //   this.$store.commit("SET_EntryPermissions", [0]);
            // } else if (res.data.permission_info.includes("添加用户")) {
            //   this.$store.commit("SET_EntryPermissions", [1]);
            // } else if (res.data.permission_info.includes("添加比赛")) {
            //   this.$store.commit("SET_EntryPermissions", [2, 3]);
            // } else if (res.data.permission_info.includes("报名审核")) {
            //   this.$store.commit("SET_EntryPermissions", [11]);
            // } else {
            //   this.$store.commit("SET_EntryPermissions", [2]);
            // }
          }
        } else {
          this.$store.commit("CHANGE_Management", false);
          this.$store.commit("SET_EntryPermissions", 0);
          sessionStorage.setItem("level_info", null);
        }
        // 从新获判断当前用户是否为管理员状态存放到VueX
        if (res.data.role_id != 2) {
          this.$store.commit("CHANGE_Management", true);
        } else {
          this.$store.commit("CHANGE_Management", false);
        }
      });
    }
  },
  mounted() {
    this.loading = this.$store.state.app.loading;
  },
  watch: {
    $route(route) {
      this.ThisRouter = route.path;
      this.OnClickIsLoginPage();
      this.ChangeState(route.path);
    },
    "$store.state.app.loading"(val) {
      this.loading = val;
    },
    "$store.state.user.ManagementStatus"(val) {
      this.ManagementCenterState = val;
    },
  },
  methods: {
    // 获取用户信息
    getInfodata() {
      getInfo().then((res) => {
        this.UserInfoAll = res.data;
      });
    },
    // 注册协议
    RegistrationAgreement() {
      window.open("https://resource.delist.cn/用户注册协议V3.pdf");
    },
    // 使用帮助
    UseHelp() {
      window.open("https://resource.delist.cn/比赛网页用户操作手册.pdf");
    },
    // 培训手册
    trainingmanual() {
      // window.open("https://resource.delist.cn/用户注册协议V3.pdf")
    },
    // 选中样式判断
    ChangeState(Url) {
      switch (Url) {
        case "/index":
          this.productServiceTitleState = 0;
          break;
        case "/CompetitionList":
          this.productServiceTitleState = 1;
          break;
        case "/ManagementCenter":
          this.productServiceTitleState = 2;
          break;
        case "/PersonalInfo":
          this.productServiceTitleState = 3;
          break;
        case "/QA":
          this.productServiceTitleState = 10;
          break;
        default:
          this.productServiceTitleState = null;
          return;
      }
    },
    // 判断是否为登录页面或者注册页面
    OnClickIsLoginPage() {
      if (getToken()) {
        this.iSLoginPage = 1;
      } else {
        this.iSLoginPage = 0;
      }
    },
    // 路由跳转
    Jump(URl) {
      if (this.$route.path == URl) {
        return;
      }
      this.$router.push(URl);
    },
    // 登录跳转
    LoginPageJump(item) {
      this.$router.push(item);
    },
    // 切换加载动画效果
    LodingData(state) {
      this.loading = state;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  // overflow: hidden;
  // position: relative;
  // position: absolute;
	// left: 50%;
	// transform: translateX(-50%);

  min-height: 100vh;
  max-height: auto;

  width: 100%;
  // max-width: 1920px;
  // min-width: 1080px;
  background-color: rgb(245, 250, 252);
  box-sizing: border-box;
  margin: 0 auto;
  overflow: hidden;
  .Login-header {
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    line-height: 80px;
    align-items: center;
    font-size: 8px;
    font-family: auto;
    // background-color: rgb(245,250,252);
    background-color: rgba(#2b67ee, 0.1);
    .wrap {
      box-sizing: border-box;
      display: flex;
      // width: 80%;
      // height: 80px;
      max-width: 1920px;
      min-width: 1080px;
      margin-left: auto;
      margin-right: auto;
      .logo {
        padding: 0 50px;
        font-size: 3em;
        font-weight: bold;
        color: #2b67ee;
        background-image: url("../assets/logo.png");
        background-size: 38px 38px;
        background-repeat: no-repeat;
        background-position: left center;
      }
      .nav {
        display: flex;
        .item {
          width: 100px;
          // margin: 0 10px;
          // padding-bottom: 10px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          color: #000;
          font-size: 2em;
          box-sizing: border-box;
          position: relative;
          cursor: pointer;
          text-align: center;
          .sup {
            background-color: #006dbb;
            border-radius: 10px;
            color: #fff;
            padding: 0 6px;

            /* transition: transform 0.3s ease; */
            width: 100%;
            width: 20px;
            height: 20px;
            // border-bottom: 3px solid #2b67ee;
          }
          .title-content-underLine {
            position: absolute;
            left: 0;
            bottom: 16px;
            transform: scaleX(0);
            transform-origin: 50% 50%;
            transition: transform 0.3s ease;
            width: 100%;
            // border-bottom: 3px solid #ff6a00;
            border-bottom: 3px solid #2b67ee;
          }
        }
        .item:hover .title-content-underLine {
          transform: scaleX(1);
        }
        .item:hover {
          color: #2b67ee;
        }
      }
      .login {
        margin-left: auto;
        line-height: 80px;
        .home-button {
          display: inline-block;
          // height: 32px;
          line-height: 32px;
          text-align: center;
          color: #000;
          border: 1px solid #000;
          margin: 23px 10px;
          width: 90px;
          font-size: 2em;
          letter-spacing: 2px;
          position: relative;
          transition: all 0.3s;
          cursor: pointer;
        }
        .home-button:after {
          content: "";
          position: absolute;
          z-index: -9;
          left: 0;
          top: 0;
          height: 100%;
          width: 0;
          background-color: #2b67ee;
          transition: all 0.3s;
        }
        .home-button:hover {
          color: #fff;
          z-index: 10;
          font-weight: bold;
          border: 1px solid #2b67ee;
        }
        .home-button:hover:after {
          width: 100%;
        }
        .item {
          display: inline-block;
          width: 120px;
          // margin: 0 10px;
          // padding-bottom: 10px;
          color: #000;
          font-size: 2em;
          box-sizing: border-box;
          position: relative;
          cursor: pointer;
          text-align: center;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          .sup {
            background-color: #006dbb;
            border-radius: 10px;
            color: #fff;
            padding: 0 6px;
            /* transition: transform 0.3s ease; */
            width: 100%;
            width: 20px;
            height: 20px;
            // border-bottom: 3px solid #2b67ee;
          }
          .title-content-underLine {
            position: absolute;
            left: 0;
            bottom: 16px;
            transform: scaleX(0);
            transform-origin: 50% 50%;
            transition: transform 0.3s ease;
            width: 100%;
            // border-bottom: 3px solid #ff6a00;
            border-bottom: 3px solid #2b67ee;
          }
        }
        .item:hover .title-content-underLine {
          transform: scaleX(1);
        }
        .item:hover {
          color: #2b67ee;
        }
      }
    }
  }
  .banner {
    // position: absolute;
    // top: 80px;
    // left: 0;
    width: 100%;
    width: 1080px;
    margin: 0 auto;
    height: 290px;
    height: 380px;
    background: url("../assets/banner/banner5.jpg") no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: rgb(245, 250, 252);
    box-sizing: border-box;
  }
  .appMainStyle {
    height: auto;
    min-width: 1080px;
    margin: 0 auto;
    margin-bottom: 100px;
    background-color: rgb(245, 250, 252);
  }
  .footer {
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 80px;
    background-color: rgb(25, 35, 55);
    line-height: 30px;
    text-align: center;
    color: #939393;
    box-sizing: border-box;
    font-size: 18px;
    .footer-bar {
      width: 1080px;
      height: 50px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      line-height: 50px;
      display: flex;
      justify-content: center;
      .item {
        width: 100px;
        height: 50px;
        color: rgb(180, 186, 172);
        text-align: center;
        cursor: pointer;
      }
      .line {
        width: 2px;
        height: 20px;
        background: rgb(160, 169, 162);
        margin-top: 15px;
      }
    }
  }
}
</style>