import axios from "axios";
import { Notification, MessageBox, Message } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";
import errorCode from "@/utils/errorCode";

// axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers["Content-Type"] = "application/json";
// axios.defaults.withCredentials = true;
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
// 创建axios实例
// https://www.delist.cn/match_api
// https://www.yuxiua.cn
// https://matchapi.delist.cn
var publicbaseURL = "https://matchapi.delist.cn";
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: publicbaseURL, //http://47.116.142.196:5000
  // 超时
  timeout: 60000,
});
// request拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    if (getToken() && !isToken) {
      config.headers["Authorization"] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // get请求映射params参数
    if (config.method === "get" && config.params) {
      let url = config.url + "?";
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value && typeof value !== "undefined") {
          if (typeof value === "object") {
            for (const key of Object.keys(value)) {
              let params = propName + "[" + key + "]";
              var subPart = encodeURIComponent(params) + "=";
              url += subPart + encodeURIComponent(value[key]) + "&";
            }
          } else {
            url += part + encodeURIComponent(value) + "&";
          }
        }
      }
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode["default"];
    if (code === 10017) {
      MessageBox.confirm(
        "登录状态已过期，您可以继续留在该页面，或者重新登录",
        "系统提示",
        {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        store.dispatch("FedLogOut").then(() => {
          location.href = "/";
          //   sessionStorage.clear();
        });
      });
    } 
    else if (code === 10042) {
		//未报名
		return Promise.reject(res.data);
	} else if (code === 10024) {
		Notification.error({
			title: msg,
		});
		//批量注册手机号补规范
		return Promise.reject(res.data);
	}else if (code === 10071) {
		Notification.error({
			title: msg,
		});
		//用户已存在
		return Promise.reject(res.data);
	} else if (code === 10061) {
		//审核中
		return Promise.reject(res.data);
	} else if (code === 500) {
		Message({
		message: msg,
		type: "error",
		});
		return Promise.reject(new Error(msg));
	} else if (code !== 200) {
		Notification.error({
		title: msg,
		});
		return Promise.reject(res.data);
	} else {
		return res.data;
	}
  },
  (error) => {
    let { message } = error;
    if (message == "Network Error") {
		message = "网络连接异常"; //后端接口连接异常
	} else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
export function publicbaseURLs() {
  return publicbaseURL;
}
